const easeInOutQuad = (
  currentTime: number,
  start: number,
  change: number,
  duration: number
): number => {
  let newCurrentTime = currentTime
  newCurrentTime /= duration / 2

  if (newCurrentTime < 1) {
    return (change / 2) * newCurrentTime * newCurrentTime + start
  }

  newCurrentTime -= 1
  return (-change / 2) * (newCurrentTime * (newCurrentTime - 2) - 1) + start
}

const linearInterpolation = (
  currentTime: number,
  start: number,
  change: number,
  duration: number
): number => {
  return start + (currentTime * change) / duration
}

const smoothScroll = (
  duration: number,
  element: HTMLElement,
  to: number,
  property: 'scrollTop' | 'scrollLeft',
  effect: 'easeInOutQuad' | 'linearInterpolation' = 'linearInterpolation'
): void => {
  const start = element[property]
  const change = to - start
  const startDate = new Date().getTime()

  const animateScroll = () => {
    const currentDate = new Date().getTime()
    const currentTime = currentDate - startDate

    switch (effect) {
      case 'easeInOutQuad':
        element[property] = easeInOutQuad(currentTime, start, change, duration)
        break
      case 'linearInterpolation':
        element[property] = linearInterpolation(
          currentTime,
          start,
          change,
          duration
        )
        break
    }

    if (currentTime < duration) {
      requestAnimationFrame(animateScroll)
    } else {
      element[property] = to
    }
  }
  animateScroll()
}

export {smoothScroll}
