import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit'
import {FetchVisitorsResult} from '../../state/types'
import type {RootState} from '../../store/store'
import visitorsAPI from './visitorAPI'

// Create thunks (async action)
export const fetchVisitors: any = createAsyncThunk('/visitors', async () => {
  process.env.DEBUG === 'true' && console.log('thunkAPI/fetchVisitors')
  const res = await visitorsAPI.fetchVisitors()
  const send: FetchVisitorsResult = {
    views: res.data.views,
    uniqueVisitors: res.data.uniqueVisitors
  }
  process.env.DEBUG === 'true' && console.log(send)
  return send
})
export const fetchVisitorId: any = createAsyncThunk(
  '/visitors/increment',
  async (visitorId: number | null, thunkAPI) => {
    process.env.DEBUG === 'true' && console.log('thunkAPI/fetchVisitorId')
    const res = await visitorsAPI.fetchVisitorId(visitorId)
    process.env.DEBUG === 'true' && console.log(res.data)
    return res.data
  }
)

// Define a type for the slice state
export interface VisitorsState {
  views: number
  uniqueVisitors: number
  visitorId: number | null
}

// Define the initial state using that type
const initialState: VisitorsState = {
  views: 0,
  uniqueVisitors: 0,
  visitorId:
    localStorage.getItem('visitorId') !== null &&
    !isNaN(Number(localStorage.getItem('visitorId')))
      ? Number(localStorage.getItem('visitorId'))
      : null
}

export const visitorsSlice = createSlice({
  name: 'visitor',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Standard reducer logic
    setViews: (state, action: PayloadAction<number>) => {
      state.views += action.payload
    },
    setUniqueVisitors: (state, action: PayloadAction<number>) => {
      state.uniqueVisitors += action.payload
    },
    setVisitorId: (state, action: PayloadAction<number>) => {
      state.visitorId = action.payload
    }
  },
  extraReducers: builder => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchVisitors.fulfilled, (state, action) => {
      state.views = action.payload.views
      state.uniqueVisitors = action.payload.uniqueVisitors
    })
    builder.addCase(fetchVisitorId.fulfilled, (state, action) => {
      state.visitorId = action.payload.visitorId
      localStorage.setItem(
        'visitorId',
        (action.payload === null ? 'null' : action.payload).toString()
      )
    })
  }
})

export const {setViews, setUniqueVisitors} = visitorsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectViews = (state: RootState) => state.visitors.views
export const selectUniqueVisitors = (state: RootState) =>
  state.visitors.uniqueVisitors
export const selectVisitorId = (state: RootState) => state.visitors.visitorId

export default visitorsSlice.reducer
