export function debounce(func: Function) {
    let timer: any | null
    return function (event: Event) {
        if (timer) clearTimeout(timer)
        timer = setTimeout(func, 100, event)
    }
}

var timer = new Date()
export function debounceWithDelay(func: Function, delay: number) {
    var now = new Date()
    var timeDiff = now.getTime() - timer.getTime()
    if (timeDiff > delay) {
        timer = now
        func()
    }
}