import {FC, ReactElement} from 'react'
import {Category, CategoryTransition, Direction, Skill} from '../state/types'
import '../styles/SkillList.scss'
import {useTranslation} from 'react-i18next'

interface Props {
  skills: Skill[]
  index: number
  categoryTransition: CategoryTransition
  category: Category
}

const SkillList: FC<Props> = ({
  skills,
  index,
  categoryTransition,
  category
}): ReactElement => {
  const [t] = useTranslation()
  return (
    <div
      key={index}
      className={`skill-list ${styleSkillList(categoryTransition, index)}`}>
      <div className="skill-header">
        <h2>{t(category.name)}</h2>
      </div>
      <div className="skill-body">
        {skills
          ? skills.map((s: Skill) => (
              <div key={s.name} className="skill">
                <div className="skill-image">
                  <img src={'image/skills/' + s.image} alt={s.name} />
                </div>
                <div className="skill-description">
                  <p className="font-s">{s.name}</p>
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  )
}

function styleSkillList(categoryTransition: CategoryTransition, index: number) {
  if (categoryTransition.direction === Direction.NEXT) {
    if (categoryTransition.index === index) {
      return 'slide-in-next'
    }
    return 'slide-out-next'
  }
  if (categoryTransition.index === index) {
    return 'slide-in-previous'
  }
  return 'slide-out-previous'
}

export default SkillList
