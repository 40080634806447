import {configureStore} from '@reduxjs/toolkit'
import counterSlice from '../features/counter/counterSlice'
import visitorsSlice from '../features/visitors/visitorsSlice'
import projectsSlice from '../features/projects/projectsSlice'
import modalSlice from '../features/modal/modalSlice'

const store = configureStore({
  reducer: {
    counter: counterSlice,
    visitors: visitorsSlice,
    projects: projectsSlice,
    modal: modalSlice
  }
  /**
   * An array of Redux middleware to install. If not supplied, defaults to
   * the set of middleware returned by `getDefaultMiddleware()`.
   */
})
export default store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
