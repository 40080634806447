import {useState, useEffect, ReactElement} from 'react'
import Banner from './Banner'
import {Route, Routes, useLocation} from 'react-router-dom'
import ProjectList from './ProjectList'
import About from './About'
import {useDispatch} from 'react-redux'
import {useAppSelector} from '../hooks/hooks'

import {fetchRating} from '../features/counter/counterSlice'
import {fetchVisitorId, fetchVisitors} from '../features/visitors/visitorsSlice'
import Splash from './Splash'
import LegalNotices from './LegalNotices'
import Footer from './Footer'
import ImageModal from './ImageModal'
import ReactTooltip from 'react-tooltip'

export const Layout = (): ReactElement => {
  // Current location (url)
  const location = useLocation()

  // Dark/Light theme management
  // Load light/dark mode preferences
  const savedLightMode = localStorage.getItem('lightMode') ?? 'default'
  const [lightMode, setLightMode] = useState<string>(savedLightMode)
  const [verticalScroll, setVerticalScroll] = useState<boolean>(true)

  const toggleTheme = () => {
    if (lightMode === 'default') {
      localStorage.setItem('lightMode', 'inverted')
      setLightMode('inverted')
    } else {
      localStorage.setItem('lightMode', 'default')
      setLightMode('default')
    }
  }

  useEffect(() => {
    if (lightMode === 'inverted') {
      process.env.DEBUG === 'true' &&
        console.log('Add is_inverted class (Enable dark mode)')
      document.body.classList.add('is_inverted')
    } else {
      process.env.DEBUG === 'true' &&
        console.log('Remove is_inverted class (Disable dark mode)')
      document.body.classList.remove('is_inverted')
    }
  }, [lightMode])

  const dispatch = useDispatch()
  const visitorId = useAppSelector(state => state.visitors.visitorId)
  useEffect(() => {
    // Inform the backend of a new visitor
    dispatch(fetchVisitorId(visitorId)).then((res: any) => {
      dispatch(fetchVisitors())
      dispatch(fetchRating(res.payload ? res.payload.visitorId : null))
    })
  }, [dispatch, visitorId])

  useEffect(() => {
    process.env.DEBUG === 'true' && console.log(location.pathname)
    if (location.pathname === '/projects') {
      setVerticalScroll(false)
      return
    }
    setVerticalScroll(true)
  }, [location])

  return (
    <>
      <ReactTooltip className="tool-tip" effect="solid" />
      <div className="flex-layout">
        <Banner lightMode={lightMode} toggleTheme={toggleTheme} />
        <ImageModal />
        <div className={'page-body ' + (verticalScroll ? '' : 'no-y-scroll')}>
          <Routes location={location}>
            <Route path="/" element={<Splash />} />
            <Route path="projects" element={<ProjectList />} />
            {/* <Route path="contact" element={<Contact />} /> */}
            <Route path="about" element={<About />} />
            <Route path="legal" element={<LegalNotices />} />
            <Route>{'404'}</Route>
          </Routes>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Layout
