import React, {FC, ReactElement, useCallback, useEffect, useState} from 'react'
import ThemeToggle from './ThemeToggle'
import {Link} from 'react-router-dom'
import '../styles/Banner.scss'
import {useTranslation} from 'react-i18next'
import ReactFlagsSelect from 'react-flags-select'
import {useDispatch} from 'react-redux'
import {fetchProjects} from '../features/projects/projectsSlice'
import {AiOutlineClose} from 'react-icons/ai'
import {FaBars} from 'react-icons/fa'
import OutsideClickHandler from 'react-outside-click-handler'
import i18n from '../i18n'

interface Props {
  lightMode: string
  toggleTheme: React.ChangeEventHandler
}

const countryLanguageTable = {
  'en-US': 'US',
  'fr-FR': 'FR',
  'de-DE': 'DE'
}

// collapsible nav menu : https://edisondevadoss.medium.com/react-js-sidebar-navigation-604c9537fbe9
export const Banner: FC<Props> = ({lightMode, toggleTheme}): ReactElement => {
  const [sidebar, setSidebar] = useState<boolean>(false)
  const toggleSideBar = () => setSidebar(!sidebar)
  const hideSideBar = () => setSidebar(false)

  const [t] = useTranslation()
  const dispatch = useDispatch()

  const convertLang = useCallback((lang: string | undefined): string => {
    for (let x in countryLanguageTable) {
      if (lang === x) {
        const tableKey = x as keyof typeof countryLanguageTable
        const country: string = Array.isArray(countryLanguageTable[tableKey])
          ? countryLanguageTable[tableKey][0]
          : countryLanguageTable[tableKey]
        return country
      }
    }
    return 'FR'
  }, [])
  const convertCountry = (country: string | undefined): string => {
    for (let x in countryLanguageTable) {
      const tableKey = x as keyof typeof countryLanguageTable
      if (Array.isArray(countryLanguageTable[tableKey])) {
        Array.from(countryLanguageTable[tableKey]).forEach(element => {
          if (element === country) {
            return tableKey
          }
        })
      } else if (countryLanguageTable[tableKey] === country) {
        return tableKey as string
      }
    }
    return 'fr-FR'
  }

  const [selected, setSelected] = useState(convertLang(i18n.language))
  const changeLanguageHandler = (country: string) => {
    if (country !== selected) {
      setSelected(country)
      let selectedLanguage = convertCountry(country)
      i18n.changeLanguage(selectedLanguage)
      localStorage.setItem('app_language', selectedLanguage)
      process.env.DEBUG === 'true' &&
        console.log('language set in localStorage: ' + selectedLanguage)
      dispatch(fetchProjects(i18n.language))
    }
  }

  // Load language preferences
  useEffect(() => {
    let language = localStorage.getItem('app_language')
    const authorized_languages = (
      process.env.AUTHORIZED_LANGUAGES ?? 'fr-FR,en-US,de-DE'
    ).split(',')
    if (language && authorized_languages.indexOf(language) > -1) {
      if (i18n.language !== language) {
        i18n.changeLanguage(language)
      }
      setSelected(convertLang(language))
    } else {
      let supportedLanguage = null
      switch (i18n.language) {
        case 'fr':
          supportedLanguage = 'fr-FR'
          break
        case 'en':
          supportedLanguage = 'en-US'
          break
        case 'de':
          supportedLanguage = 'de-DE'
          break
        default:
          supportedLanguage = 'fr-FR'
          break
      }
      i18n.changeLanguage(supportedLanguage)
      localStorage.setItem('app_language', supportedLanguage)
      process.env.DEBUG === 'true' &&
        console.log('language set in localStorage: ' + supportedLanguage)
      dispatch(fetchProjects(i18n.language))
    }
  }, [convertLang])

  const title = 'ANTONIN MARXER'

  return (
    <nav className={'page-header'}>
      <div className="navbar">
        <Link to="#" className="menu-bars clickable" onClick={toggleSideBar}>
          <FaBars className="icon" size="22px" />
        </Link>
        <div className="right">
          <Link className="clickable" to="/">
            <h1 className="banner-title">{title}</h1>
          </Link>
        </div>
      </div>
      <OutsideClickHandler onOutsideClick={hideSideBar}>
        <div className={'nav-menu ' + (sidebar ? 'active' : '')}>
          <ul className="left">
            <li className="navbar-toggle clickable" onClick={toggleSideBar}>
              <Link to="#" className="menu-bars">
                <AiOutlineClose className="icon" />
              </Link>
            </li>
            <li>
              <ThemeToggle lightMode={lightMode} toggleTheme={toggleTheme} />
            </li>
            <li>
              <ReactFlagsSelect
                className="country-selector"
                selected={selected}
                onSelect={code => changeLanguageHandler(code)}
                countries={['US', 'FR', 'DE']}
                customLabels={{
                  US: 'EN-US',
                  FR: 'FR',
                  DE: 'DE'
                }}
                placeholder={t('select-language')}
              />
            </li>
            <li onClick={toggleSideBar}>
              <Link
                data-item={t('projects')}
                className="link"
                to="/projects"
                id={'project-link'}>
                {t('projects')}
              </Link>
            </li>
            {/*
        <li>
          <Link data-item={t('contact')} className="link" to="/contact">
            {t('contact')}
          </Link>
        </li>
        */}
            <li onClick={toggleSideBar}>
              <Link data-item={t('about')} className="link" to="/about">
                {t('about')}
              </Link>
            </li>
          </ul>
          <div className="right" onClick={toggleSideBar}>
            <Link className="clickable" to="/">
              <h1 className="banner-title">{title}</h1>
            </Link>
          </div>
        </div>
      </OutsideClickHandler>
    </nav>
  )
}
export default Banner
