import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../../store/store'
import counterAPI from './counterAPI'

// Create thunks (async action)
export const fetchRating: any = createAsyncThunk(
  '/rating',
  async (visitorId: number | null, thunkAPI) => {
    process.env.DEBUG === 'true' && console.log('thunkAPI/rating')
    const res = await counterAPI.fetchRating(visitorId)
    process.env.DEBUG === 'true' && console.log(res)
    return {rating: res.data.rating, vote: res.data.vote ? res.data.vote : 0}
  }
)
export type PostVoteInfo = {
  visitorId: number | null
  vote: number
  up: boolean
}
export const postVote: any = createAsyncThunk(
  '/rating/vote',
  async (info: PostVoteInfo, thunkAPI) => {
    process.env.DEBUG === 'true' && console.log('thunkAPI/rating/vote')
    let vote = 0
    if (info.up) {
      if (info.vote < 1) {
        vote = 1
      }
    } else if (info.vote > -1) {
      vote = -1
    }
    const res = await counterAPI.postVote(info.visitorId, vote)
    return {rating: res.data.rating, vote: res.data.vote}
  }
)

// Define a type for the slice state
interface CounterState {
  rating: number
  vote: number
}

// Define the initial state using that type
const initialState: CounterState = {
  rating: 0,
  vote: 0
}

export const counterSlice = createSlice({
  name: 'counter',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setRating: (state, action: PayloadAction<number>) => {
      state.rating += action.payload
    }
  },
  extraReducers: builder => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchRating.fulfilled, (state, action) => {
      state.rating = action.payload.rating
      state.vote = action.payload.vote
    })
    builder.addCase(postVote.fulfilled, (state, action) => {
      state.rating = action.payload.rating
      state.vote = action.payload.vote
    })
  }
})

export const {setRating} = counterSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectRating = (state: RootState) => state.counter.rating
export const selectVote = (state: RootState) => state.counter.vote

export default counterSlice.reducer
