import {FC, ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import '../styles/Legal.scss'

interface Props {
  /* destructured props and types */
}
export const About: FC<Props> = (): ReactElement => {
  const [t] = useTranslation()

  return (
    <div className="legal-wrapper">
      <div className="legal-card margin-32px">
        <h3>{t('Domain Information')}</h3>
        <ul>
          <li>
            <span className="bold">{t('Domain')}:</span> antoninmarxer.com
          </li>
          <li>
            <span className="bold">{t('Registrar')}:</span> NameCheap, Inc.
          </li>
          <li>
            <span className="bold">{t('Registered On')}:</span> 2022-10-15
          </li>
          <li>
            <span className="bold">{t('Expires On')}:</span> 2023-10-15
          </li>
          <li>
            <span className="bold">{t('Updated On')}:</span> 2022-10-15
          </li>
        </ul>
      </div>
      <div className="legal-card margin-32px">
        <h3>{t('Registrant Contact')}</h3>
        <ul>
          <li>
            <span className="bold">{t('Street')}:</span> 20 Avenue Albert
            Einstein
          </li>
          <li>
            <span className="bold">{t('City')}:</span> Villeurbanne
          </li>
          <li>
            <span className="bold">{t('Region')}:</span> Auvergne Rhône-Alpes
          </li>
          <li>
            <span className="bold">{t('Postal Code')}:</span> 69100
          </li>
          <li>
            <span className="bold">{t('Country')}:</span> France
          </li>
          <li>
            <span className="bold">{t('Phone')}:</span> +336.44869778
          </li>
          <li>
            <span className="bold">{t('Email')}:</span> antoninmarxer@orange.fr
          </li>
        </ul>
      </div>
      <div className="legal-card margin-32px">
        <h3>{t('Administrative Contact')}</h3>
        <ul>
          <li>
            <span className="bold">{t('Street')}:</span> 20 Avenue Albert
            Einstein
          </li>
          <li>
            <span className="bold">{t('City')}:</span> Villeurbanne
          </li>
          <li>
            <span className="bold">{t('Region')}:</span> Auvergne Rhône-Alpes
          </li>
          <li>
            <span className="bold">{t('Postal Code')}:</span> 69100
          </li>
          <li>
            <span className="bold">{t('Country')}:</span> France
          </li>
          <li>
            <span className="bold">{t('Phone')}:</span> +336.44869778
          </li>
          <li>
            <span className="bold">{t('Email')}:</span> antoninmarxer@orange.fr
          </li>
        </ul>
      </div>
      <div className="legal-card margin-32px">
        <h3>{t('Technical Contact')}</h3>
        <ul>
          <li>
            <span className="bold">{t('Street')}:</span> Kalkofnsvegur 2
          </li>
          <li>
            <span className="bold">{t('City')}:</span> Villeurbanne
          </li>
          <li>
            <span className="bold">{t('Region')}:</span> Auvergne Rhône-Alpes
          </li>
          <li>
            <span className="bold">{t('Postal Code')}:</span> 69100
          </li>
          <li>
            <span className="bold">{t('Country')}:</span> France
          </li>
          <li>
            <span className="bold">{t('Phone')}:</span> +336.44869778
          </li>
          <li>
            <span className="bold">{t('Email')}:</span> antoninmarxer@orange.fr
          </li>
        </ul>
      </div>
      <div className="legal-card margin-32px center-flex">
        <h2>{t('rights-reserved')}</h2>
      </div>
    </div>
  )
}

export default About
