// Credit: Aphinya Dechalert, https://www.dottedsquirrel.com/circular-progress-css/

export function initProgressBar() {
  const numb = document.querySelector('.number') as HTMLElement
  const circle = document.querySelector('.circular') as HTMLElement
  const progressRight = document.querySelector(
    '.circle .right .progress'
  ) as HTMLElement
  const progressLeft = document.querySelector(
    '.circle .left .progress'
  ) as HTMLElement

  let counter = 0
  const duration = 1000
  const step = duration / 100

  const interval = setInterval(() => {
    if (counter === 100) {
      progressLeft.style.transform = `rotate(180deg)`
      progressRight.style.transform = `rotate(180deg)`
      circle.classList.add('inactive')
      clearInterval(interval)
    } else if (counter <= 50) {
      progressLeft.style.transform = `rotate(${counter * 2 * 1.8}deg)`
    } else {
      progressLeft.style.transform = `rotate(180deg)`
      progressRight.style.transform = `rotate(${(counter - 50) * 2 * 1.8}deg)`
    }
    numb.textContent = counter + '%'
    counter += 1
  }, step)
}
