export type Firm = {
  name: string,
  image: string,
  url?: string
}

export type CategoryTransition = {
  index: number
  direction: Direction
}

export enum Direction {
  NEXT = 0,
  PREVIOUS = 1
}

export type Category = {
  id: string
  name: string
  index: number
}

export type Skill = {
  name: string
  image: string
  category: string
  startDate: string
  training: string[] | null
}

export type Project = {
  id: number
  attributes: {
    title: string | null
    startDate: Date | null
    endDate: Date | null
    keywords: string[] | null
    role: string | null
    client: string | null
    description: string | null
    url: string | null
    cover: StrapiImage | null
    content: string | null
    video: string | null
  }
}

export type StrapiImage = {
  data: {
    id: number
    attributes: {
      name: string
      formats: {
        thumbnail: {
          name: string
          url: string
        }
        large: {
          name: string
          url: string
        } | null
        medium: {
          name: string
          url: string
        } | null
        small: {
          name: string
          url: string
        } | null
      }
    }
  }
}

export type FetchVisitorsResult = {
  views: number
  uniqueVisitors: number
}
