export const ProjectIcon = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M2342 5050 c-370 -36 -727 -149 -1029 -325 -668 -390 -1106 -1035
-1225 -1800 -31 -199 -31 -531 0 -730 103 -664 448 -1240 982 -1639 803 -600
1896 -658 2759 -147 365 216 666 517 882 882 510 861 453 1956 -144 2755 -345
463 -820 784 -1372 930 -74 19 -194 44 -267 55 -154 23 -448 32 -586 19z
m1440 -1027 c17 -15 18 -40 18 -373 l0 -357 -27 -14 c-16 -7 -31 -15 -34 -17
-4 -1 -9 16 -13 39 -9 59 -58 105 -119 114 -84 13 -250 2 -281 -18 -35 -23
-66 -74 -66 -109 0 -16 -2 -28 -4 -28 -3 0 -26 11 -52 25 -50 27 -78 31 -118
16 -30 -12 -159 -132 -198 -185 -37 -51 -38 -108 -3 -160 14 -20 25 -39 25
-41 0 -2 -18 -6 -40 -10 -46 -8 -84 -35 -105 -75 -21 -40 -21 -266 -1 -315 17
-39 68 -75 107 -75 37 0 41 -9 18 -41 -24 -34 -34 -87 -25 -125 8 -31 166
-198 208 -220 42 -22 100 -18 142 11 45 31 46 31 46 0 0 -43 27 -94 62 -115
29 -17 50 -20 166 -20 160 0 191 10 222 74 11 23 20 52 20 64 0 27 4 27 40 0
l30 -22 0 -461 c0 -401 -2 -463 -16 -483 l-15 -22 -1048 0 -1048 0 -19 21
c-18 21 -19 59 -19 1459 0 1400 1 1438 19 1459 l19 21 1045 0 c959 0 1047 -1
1064 -17z m-2497 -124 c99 -27 144 -97 145 -221 l0 -78 -211 0 -212 0 5 83 c6
90 20 127 66 170 53 51 128 67 207 46z m199 -385 c20 -20 21 -83 0 -112 -15
-22 -18 -22 -259 -22 -279 0 -275 -1 -275 79 0 26 5 52 12 59 9 9 80 12 259
12 212 0 250 -2 263 -16z m2146 -182 c15 -12 26 -37 34 -82 10 -60 13 -65 43
-73 27 -8 37 -5 78 27 71 54 85 50 187 -52 102 -102 106 -117 52 -188 -32 -41
-35 -51 -27 -78 8 -29 14 -32 71 -42 97 -18 102 -25 102 -166 0 -66 -5 -128
-10 -138 -11 -21 -67 -40 -116 -40 -23 0 -34 -6 -42 -25 -17 -38 -15 -46 23
-94 53 -67 49 -82 -53 -184 -102 -102 -118 -106 -192 -52 -43 31 -51 34 -78
24 -28 -10 -31 -15 -37 -71 -11 -93 -19 -98 -173 -98 -153 0 -158 3 -168 98
-6 57 -9 62 -37 72 -27 9 -36 7 -69 -17 -21 -14 -41 -30 -44 -35 -3 -4 -20 -8
-37 -8 -27 0 -46 14 -120 88 -101 101 -105 116 -52 185 27 35 34 52 29 71 -8
35 -21 42 -83 49 -86 10 -91 19 -91 166 0 69 4 131 8 137 12 19 45 31 98 39
42 5 54 11 63 31 15 35 15 36 -24 85 -56 71 -51 90 54 192 59 58 96 87 111 87
13 0 46 -17 74 -38 48 -37 53 -38 82 -26 29 12 32 18 38 74 11 94 21 100 165
100 99 0 122 -3 141 -18z m-2146 -24 c9 -12 16 -39 16 -60 0 -68 -2 -68 -279
-68 -179 0 -250 3 -259 12 -7 7 -12 33 -12 59 0 80 -4 79 275 79 241 0 244 0
259 -22z m-374 -911 c0 -686 -1 -715 -19 -731 -10 -9 -31 -18 -47 -19 l-29 -2
-3 733 -2 732 50 0 50 0 0 -713z m160 -6 l0 -719 -29 -7 c-17 -3 -39 -1 -50 5
-21 11 -21 14 -21 725 l0 715 50 0 50 0 0 -719z m160 -16 c0 -810 5 -753 -60
-720 l-30 16 0 719 0 720 45 0 45 0 0 -735z m-205 -777 c24 0 52 5 61 10 13 7
26 4 48 -9 17 -10 39 -19 49 -19 23 0 21 -15 -12 -79 l-28 -55 -121 0 -120 -1
-31 63 c-17 34 -31 65 -31 67 0 3 10 5 23 5 12 0 36 8 52 19 22 13 35 16 48 9
11 -5 38 -10 62 -10z m75 -228 c0 -13 -68 -150 -75 -150 -9 0 -84 144 -79 152
8 12 154 10 154 -2z"/>
                <path d="M2490 3805 l0 -35 230 0 230 0 0 35 0 35 -230 0 -230 0 0 -35z" />
                <path d="M2270 3585 l0 -35 445 0 445 0 0 35 0 35 -445 0 -445 0 0 -35z" />
                <path d="M1880 3095 l0 -35 375 0 375 0 0 35 0 35 -375 0 -375 0 0 -35z" />
                <path d="M1880 2875 l0 -35 375 0 375 0 0 35 0 35 -375 0 -375 0 0 -35z" />
                <path d="M1880 2655 l0 -35 375 0 375 0 0 35 0 35 -375 0 -375 0 0 -35z" />
                <path d="M2219 2372 c-130 -34 -240 -124 -299 -245 -141 -291 43 -628 368
-672 l52 -7 0 236 0 236 235 0 c181 0 235 3 235 13 -1 41 -33 153 -59 206 -75
148 -250 252 -419 250 -26 0 -77 -8 -113 -17z"/>
                <path d="M2470 1554 l0 -236 58 7 c194 24 354 167 396 355 28 122 51 110 -219
110 l-235 0 0 -236z"/>
                <path d="M3100 1495 l0 -35 170 0 170 0 0 35 0 35 -170 0 -170 0 0 -35z" />
                <path d="M2980 1355 l0 -35 295 0 295 0 0 35 0 35 -295 0 -295 0 0 -35z" />
                <path d="M3437 3009 c-108 -17 -199 -86 -249 -187 -30 -60 -33 -75 -33 -152 1
-106 30 -174 105 -244 76 -71 127 -90 235 -90 79 0 97 3 148 29 235 118 259
444 43 589 -77 52 -160 70 -249 55z"/>
            </g>
        </svg>
    )
}


