import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import type {RootState} from '../../store/store'
import projectsAPI from './projectsAPI'

// Create thunks (async action)
export const fetchProjects: any = createAsyncThunk(
  '/projects',
  async (language: string) => {
    process.env.DEBUG === 'true' && console.log('thunkAPI/fetchProjects')
    const res = await projectsAPI.fetchProjects(language)
    process.env.DEBUG === 'true' && console.log(res)
    return res.data
  }
)

// Define the initial state using that type
const initialState = {
  projects: []
}

export const projectsSlice = createSlice({
  name: 'projects',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      process.env.DEBUG === 'true' && console.log(action.payload)
      state.projects = action.payload.data
    })
  }
})

// Other code such as selectors can use the imported `RootState` type
export const selectProjects = (state: RootState) => state.projects.projects

export default projectsSlice.reducer
