import {smoothScroll} from './smoothScroll'
import {vw} from './units'
import {updateURLParameter} from './url'
import {debounce} from './debounce'

// Handle mobile gestures
// cf https://gist.github.com/SleepWalker/da5636b1abcbaff48c4d#file-swipe-js
let touchstartX = 0
let touchstartY = 0
let touchendX = 0
let touchendY = 0

// PARAMETERS
const minXScroll: number = 70 // in px. Minimum value of scroll/swipe on the horizontal axis
const minYScroll: number = 70 // in px. Minimum value of scroll/swipe on the vertical axis

const gapPassive: number = 5 // in px
const gapActive: number = 5 // in px

const minHeightPercentage = 50 // in %, project height when it's in passive state
const hoverHeightPercentage = 90 // in %, project height when it's hovered
const maxHeightPercentage = 90 // in %, project height when it's open (active)
const itemZoomInWidth: number = 70 // in vw
const itemZoomInWidthPhone: number = 100 // in vw
const phoneMaxWidth: number = 560 // in px
const itemZoomOutWidth: number = 25 // in vw
const itemZoomOutMaxWidth: number = 150 // in px
const shadowWhenVisited: boolean = false

const shortShortDuration: number = 260 // in ms
const shortDuration: number = 500 // in ms
const mediumDuration: number = 1000 // in ms
const longDuration: number = 2000 // in ms

// SET VARIABLES IN CSS
let html = document.querySelector('html')
html?.style.setProperty('--short-duration', shortDuration + 'ms')
html?.style.setProperty('--medium-duration', mediumDuration + 'ms')
html?.style.setProperty('--long-duration', longDuration + 'ms')
html?.style.setProperty('--item-zoom-in-width', itemZoomInWidth + 'vw')
html?.style.setProperty(
  '--item-zoom-in-width-phone',
  itemZoomInWidthPhone + 'vw'
)
html?.style.setProperty('--phone-max-width', phoneMaxWidth + 'px')
html?.style.setProperty('--item-zoom-out-width', itemZoomOutWidth + 'vw')
html?.style.setProperty('--item-zoom-out-max-width', itemZoomOutMaxWidth + 'px')
html?.style.setProperty('--min-height-percentage', minHeightPercentage + '%')
html?.style.setProperty('--max-height-percentage', maxHeightPercentage + '%')
html?.style.setProperty(
  '--hover-height-percentage',
  hoverHeightPercentage + '%'
)
html?.style.setProperty('--gap-passive', gapPassive + 'px')
html?.style.setProperty('--gap-active', gapActive + 'px')

// EXECUTION VARIABLES
let sticky: HTMLElement
let clickedProject: HTMLElement | null = null
let mousedownIsAllowed: boolean = false
setTimeout(() => {
  mousedownIsAllowed = true
}, longDuration) // After the projects loading animation, allow to click on them

window.onbeforeunload = function () {
  // On page refresh:
  // Reset scroll for each project
  const projects = Array.from(
    document.getElementsByClassName('project-container')
  ) as HTMLElement[]
  projects.forEach(p => {
    p.scrollTop = 0
  })
}

// When going pack to project page:
export function bindProjectLink() {
  document.getElementById('project-link')!.addEventListener('mousedown', () => {
    process.env.DEBUG === 'true' && console.log('Disable animation')
    // disable slide in animation
    const projectList = document.getElementsByClassName('project-list')[0]
    if (projectList != null) {
      projectList.classList.add('preload')
      setTimeout(() => {
        projectList.classList.remove('preload')
      }, longDuration)

      // disable hover animation
      const projects = Array.from(
        projectList.getElementsByClassName('project')
      ) as HTMLElement[]
      projects.forEach(p => {
        p.classList.add('preload')
        setTimeout(() => {
          p.classList.remove('preload')
        }, shortDuration)
      })
    }

    const projectInnerList = document.getElementsByClassName('project-style')[0]
    if (projectInnerList != null) {
      projectInnerList.classList.add('preload')
      setTimeout(() => {
        projectInnerList.classList.remove('preload')
      }, longDuration)
    }
  })
}

export function closeByIndex(
  index: number,
  closeAll: boolean,
  scrollBack: boolean
) {
  process.env.DEBUG === 'true' && console.log('closeByIndex')
  const projects = Array.from(
    document.getElementsByClassName('project')
  ) as HTMLElement[]
  for (const project of projects) {
    if (
      Number(project.getAttribute('data-key')) === index &&
      project.classList.contains('active')
    ) {
      if (closeAll) {
        sticky.classList.remove('active')
        sticky.firstElementChild?.classList.remove('active')
        // Reset url
        window.history.replaceState('', '', window.location.href.split('?')[0])
      }
      close(project)

      if (scrollBack) {
        let itemZoomOutWidthTmp = vw(itemZoomOutWidth)
        if (itemZoomOutWidthTmp > itemZoomOutMaxWidth) {
          itemZoomOutWidthTmp = itemZoomOutMaxWidth
        }
        // const scrollBarWidth = getScrollbarWidth()
        const offsetWidth = itemZoomOutWidthTmp - project.offsetWidth
        const toScroll =
          project.offsetLeft +
          project.offsetWidth / 2 -
          sticky.offsetWidth / 2 +
          offsetWidth / 2
        smoothScroll(shortDuration, sticky, toScroll, 'scrollLeft')
        break
      }
    }
  }
}

export function close(project: HTMLElement) {
  project.classList.remove('active')
  // Add visited state
  if (shadowWhenVisited) {
    project.classList.add('visited')
  }

  // Add inactive animation
  project.classList.add('inactive')
  // Remove inactive animation after animation
  const lastClickedProject = clickedProject
  setTimeout(() => {
    if (lastClickedProject?.classList.contains('inactive')) {
      lastClickedProject.classList.remove('inactive')
    }
  }, shortDuration)
  clickedProject = null
}

export function openProjectById(id: number) {
  process.env.DEBUG === 'true' && console.log('openProjectById: ' + id)
  const projects = Array.from(
    document.getElementsByClassName('project')
  ) as HTMLElement[]
  for (const project of projects) {
    if (Number(project.getAttribute('data-project-id')) === id) {
      process.env.DEBUG === 'true' && console.log('navigate to project ' + id)
      openProject(project, project)
      break
    }
  }
}

export function openProjectByIndex(index: number) {
  process.env.DEBUG === 'true' && console.log('openProjectByIndex: ' + index)
  const projects = Array.from(
    document.getElementsByClassName('project')
  ) as HTMLElement[]
  for (const project of projects) {
    if (Number(project.getAttribute('data-key')) === index) {
      process.env.DEBUG === 'true' &&
        console.log('navigate to project ' + index)
      openProject(project, project)
      break
    }
  }
}

export function openProject(containerElem: HTMLElement, elem: HTMLElement) {
  // Handle the click event ONLY IF the user isn't clicking inside of the currently open project:
  if (clickedProject !== containerElem) {
    sticky.classList.add('active')

    window.history.replaceState(
      '',
      '',
      updateURLParameter(
        window.location.href,
        'project',
        containerElem.getAttribute('data-project-id')!
      )
    )

    // Remove last clicked project's style
    if (clickedProject != null) {
      clickedProject.classList.remove('active')
      if (shadowWhenVisited) {
        // Add visited state
        clickedProject.classList.add('visited')
      }

      // Add inactive animation
      clickedProject.classList.add('inactive')
      // Remove inactive animation after animation
      const lastClickedProject = clickedProject
      setTimeout(() => {
        if (lastClickedProject?.classList.contains('inactive')) {
          lastClickedProject.classList.remove('inactive')
        }
      }, shortDuration)
    }

    setTimeout(() => {
      navigateToProject(containerElem)
    }, 700) // Center the project

    // Stylize last clicked project
    clickedProject = containerElem
    clickedProject.classList.add('active')
    if (clickedProject.classList.contains('inactive')) {
      clickedProject.classList.remove('inactive')
    }
  }
}

export function click(containerElem: HTMLElement, elem: HTMLElement) {
  if (mousedownIsAllowed) {
    openProject(containerElem, elem)
  }
}

const SCROLL_DURATION = 100
let scrollTimer = new Date()

let redoScroll: boolean = false
const debounceScroll = (f: Function) => {
  let now = new Date()
  let timeDiff = now.getTime() - scrollTimer.getTime()
  if (timeDiff > SCROLL_DURATION) {
    scrollTimer = now
    f()
  } else if (!redoScroll) {
    redoScroll = true
    setTimeout(() => {
      redoScroll = false
      debounceScroll(f)
    }, SCROLL_DURATION - timeDiff)
  }
}

function horizontalScroll(deltaX: number, deltaY: number) {
  if (clickedProject === null) {
    if (deltaX < 0 && deltaX > -minXScroll) {
      deltaX = -minXScroll
    } else if (deltaX > 0 && deltaX < minXScroll) {
      deltaX = minXScroll
    }

    if (deltaY < 0 && deltaY > -minYScroll) {
      deltaY = -minYScroll
    } else if (deltaY > 0 && deltaY < minYScroll) {
      deltaY = minYScroll
    }

    let projects = Array.from(document.getElementsByClassName('project'))
    let firstOffsetLeft = (projects[0] as HTMLElement).offsetLeft
    let secondOffsetLeft = (projects[projects.length - 1] as HTMLElement)
      .offsetLeft
    let scrollWidth = secondOffsetLeft - firstOffsetLeft
    let scrollStart = (projects[0] as HTMLElement).offsetLeft

    // The scrolling interval goes from scrollStart to (scrollStart+scrollWidth) in px
    // Each project's offsetLeft attribute is set within this interval.
    const addScroll = (deltaX + deltaY) * 1.5
    const effectCoordinates = sticky.scrollLeft + addScroll
    projects.forEach(project => {
      let elem = project as HTMLElement
      let percentage =
        1 -
        Math.abs(effectCoordinates + scrollStart - elem.offsetLeft) /
          scrollWidth
      // percentage is between 0 and 1
      let heightPercentage = Math.max(
        minHeightPercentage,
        minHeightPercentage +
          percentage * (maxHeightPercentage - minHeightPercentage)
      )

      // trigger hover effect by scrolling
      // The duration of the hover effect is proportional to the percentage.
      // The bigger the percentage, the nearest the project is from the center.
      elem.style['height'] = heightPercentage + '%'
      elem.style['filter'] = 'grayscale(' + (1 - percentage) * 100 + '%)'

      setTimeout(() => {
        elem.style.removeProperty('height')
        elem.style.removeProperty('filter')
      }, shortShortDuration)
    })

    // sticky.scrollLeft += 0.1 * ((e as WheelEvent).deltaY + (e as WheelEvent).deltaX)
    const toScroll = sticky.scrollLeft + addScroll
    smoothScroll(SCROLL_DURATION, sticky, toScroll, 'scrollLeft')
  }
}

function swipeProjectGesture() {
  // Invert swipe on x-axis and y-axis
  debounceScroll(() =>
    horizontalScroll(-touchendX + touchstartX, -touchendY + touchstartY)
  )
}

function bindScroll() {
  if ('ontouchstart' in window) {
    // Add mobile gestures event listeners
    sticky.addEventListener(
      'touchstart',
      event => {
        if (!sticky.classList.contains('active')) {
          // event.preventDefault()
          let touchEvent = event
          touchstartX = touchEvent.changedTouches[0].screenX
          touchstartY = touchEvent.changedTouches[0].screenY
        }
      },
      false
    )
    sticky.addEventListener(
      'touchmove',
      event => {
        if (!sticky.classList.contains('active')) {
          // event.preventDefault()
          let touchEvent = event
          touchendX = touchEvent.changedTouches[0].screenX
          touchendY = touchEvent.changedTouches[0].screenY
          handleGesture(swipeProjectGesture, null)
        }
      },
      false
    )
    sticky.addEventListener(
      'touchend',
      event => {
        if (!sticky.classList.contains('active')) {
          let touchEvent = event
          touchendX = touchEvent.changedTouches[0].screenX
          touchendY = touchEvent.changedTouches[0].screenY
          handleGesture(swipeProjectGesture, null)
        }
      },
      false
    )
  } else {
    sticky.addEventListener('wheel', (e: Event) => {
      const wheelEvent = e as WheelEvent
      if (!sticky.classList.contains('active')) {
        e.preventDefault()
        debounceScroll(() =>
          horizontalScroll(wheelEvent.deltaX, wheelEvent.deltaY)
        )
      }
    })
  }
}

function handleGesture(swipeFunc: Function | null, tapFunc: Function | null) {
  if (!swipeFunc) {
    return
  }
  if (touchendX < touchstartX) {
    process.env.DEBUG === 'true' && console.log('Swiped Left')
    swipeFunc()
  } else if (touchendX > touchstartX) {
    process.env.DEBUG === 'true' && console.log('Swiped Right')
    swipeFunc()
  }

  if (touchendY < touchstartY) {
    process.env.DEBUG === 'true' && console.log('Swiped Up')
  } else if (touchendY > touchstartY) {
    process.env.DEBUG === 'true' && console.log('Swiped Down')
  }

  if (touchendX === touchstartX && touchendY === touchstartY) {
    process.env.DEBUG === 'true' && console.log('Tap')
    if (tapFunc) {
      tapFunc()
    }
  }
}

export function navigateToProjectByIndex(index: number) {
  const projects = Array.from(
    document.getElementsByClassName('project')
  ) as HTMLElement[]
  for (const project of projects) {
    if (Number(project.getAttribute('data-key')) === index) {
      navigateToProject(project, true)
      break
    }
  }
}

export function navigateToProject(
  elem: HTMLElement,
  closeCurrentProject = false
) {
  // get project id
  let projectKey = elem.getAttribute('data-key')

  // get corresponding project
  let project
  if (elem.classList.contains('project')) {
    project = elem
  } else {
    project = Array.from(document.getElementsByClassName('project')).find(p => {
      return p.getAttribute('data-key') === projectKey
    }) as HTMLElement
  }

  const offsetWidth = closePreviousProject(closeCurrentProject, project)
  scrollToElem(project, offsetWidth)
}

function closePreviousProject(
  closeCurrentProject: boolean,
  project: HTMLElement
): number {
  let offsetWidth = 0
  if (
    closeCurrentProject &&
    clickedProject !== null &&
    clickedProject !== project
  ) {
    let modifyGaps = sticky.classList.contains('active')

    if (modifyGaps) {
      sticky.classList.remove('active')
      sticky.firstElementChild?.classList.remove('active')
    }

    // Reset url
    window.history.replaceState('', '', window.location.href.split('?')[0])

    if (
      clickedProject.getAttribute('data-key')! <
      project.getAttribute('data-key')!
    ) {
      offsetWidth *= -1
      let itemZoomOutWidthTmp = vw(itemZoomOutWidth)
      if (itemZoomOutWidthTmp > itemZoomOutMaxWidth) {
        itemZoomOutWidthTmp = itemZoomOutMaxWidth
      }
      // When we close a project, we compute the width offset that will happen
      offsetWidth += itemZoomOutWidthTmp - clickedProject.offsetWidth
    }
    close(clickedProject)
  }
  return offsetWidth
}

function scrollToElem(project: HTMLElement, offsetWidth: number) {
  let itemWidth
  if (project.classList.contains('active')) {
    let windowWidth =
      window.innerWidth > 0 ? window.innerWidth : window.screen.width
    process.env.DEBUG === 'true' &&
      console.log(
        'navigate: ' + (windowWidth <= phoneMaxWidth ? 'phone' : 'desktop')
      )
    itemWidth =
      windowWidth <= phoneMaxWidth
        ? vw(itemZoomInWidthPhone)
        : vw(itemZoomInWidth)
  } else {
    itemWidth = vw(itemZoomOutWidth)
    if (itemWidth > itemZoomOutMaxWidth) {
      itemWidth = itemZoomOutMaxWidth
    }
  }
  process.env.DEBUG === 'true' && console.log(project)

  let toScroll =
    project.offsetLeft +
    itemWidth / 2 -
    sticky.offsetWidth / 2 +
    offsetWidth / 2

  smoothScroll(shortDuration, sticky, toScroll, 'scrollLeft')
}

window.addEventListener(
  'resize',
  debounce(function () {
    // At the end of a window resize event,
    // Center the clicked project
    if (clickedProject !== null) {
      setTimeout(() => {
        navigateToProject(clickedProject as HTMLDivElement)
      }, mediumDuration)
    }
  })
)

export function init() {
  sticky = document.getElementsByClassName('scrollable-x')[0] as HTMLElement

  bindScroll()
  bindProjectLink()

  // Prevent default animation: gap increase between projects
  const projectInnerList = document.getElementsByClassName('project-style')[0]
  projectInnerList.classList.add('preload')
  setTimeout(() => {
    projectInnerList.classList.remove('preload')
  }, longDuration)

  const urlParams = new URLSearchParams(window.location.search)
  const projectId = urlParams.get('project')
  process.env.DEBUG === 'true' &&
    console.log('Check params for projectId parameter: ' + projectId)
  if (projectId && !isNaN(+projectId)) {
    process.env.DEBUG === 'true' && console.log('Disable slide in animation')
    // Disable all animations
    sticky.classList.add('preload')
    setTimeout(() => {
      sticky.classList.remove('preload')
    }, longDuration)

    openProjectById(Number(projectId))
  }
}
