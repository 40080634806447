import axios from 'axios'

const ratingAPI = {
  fetchRating,
  postVote
}
export default ratingAPI

async function fetchRating(visitorId: number | null) {
  process.env.DEBUG === 'true' && console.log('fetchRating')
  process.env.DEBUG === 'true' &&
    console.log({
      id: visitorId
    })
  const res = await axios({
    method: 'POST',
    url:
      (process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:8080') +
      '/rating',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept',
      'content-type': 'application/json; charset=utf-8'
    },
    data: {
      id: visitorId
    }
  })
  return res
}

async function postVote(visitorId: number | null, vote: number) {
  process.env.DEBUG === 'true' && console.log('postVote')
  process.env.DEBUG === 'true' &&
    console.log({
      id: visitorId,
      vote: vote
    })
  const res = await axios({
    method: 'POST',
    url:
      (process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:8080') +
      '/rating/vote',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept',
      'content-type': 'application/json; charset=utf-8'
    },
    data: {
      id: visitorId,
      vote: vote
    }
  })
  return res
}
