import axios from 'axios'

const visitorAPI = {
  fetchVisitorId,
  fetchVisitors
}
export default visitorAPI

async function fetchVisitorId(visitorId: number | null) {
  process.env.DEBUG === 'true' &&
    console.log(
      (process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:8080') +
        '/visitors/increment'
    )
  const res = await axios({
    method: 'POST',
    url:
      (process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:8080') +
      '/visitors/increment',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept',
      'content-type': 'application/json; charset=utf-8'
    },
    data: {
      id: visitorId
    }
  })
  return res
}

async function fetchVisitors() {
  const res = await axios({
    method: 'GET',
    url:
      (process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:8080') +
      '/visitors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept',
      'content-type': 'application/json; charset=utf-8'
    }
  })
  return res
}
