import {createSlice} from '@reduxjs/toolkit'
import type {RootState} from '../../store/store'

export type ImageInfo = {
  readonly src: string
  readonly alt: string
}

const initialState = {
  images: [] as ImageInfo[],
  index: 0,
  visible: false
}

export const modalSlice = createSlice({
  name: 'modal',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setImages: (state, action: {payload: ImageInfo[]}) => {
      state.images = action.payload
    },
    setIndex: (state, action: {payload: number}) => {
      state.index = action.payload
    },
    setVisible: (state, action: {payload: boolean}) => {
      state.visible = action.payload
    },
    nextImg: state => {
      if (state.index < state.images.length - 1) {
        state.index = state.index + 1
      }
    },
    previousImg: state => {
      if (state.index > 0) {
        state.index = state.index - 1
      }
    }
  }
})

// Other code such as selectors can use the imported `RootState` type
export const selectImages = (state: RootState) => state.modal.images
export const selectIndex = (state: RootState) => state.modal.index
export const selectVisible = (state: RootState) => state.modal.visible

export const {setImages, setIndex, setVisible, nextImg, previousImg} =
  modalSlice.actions

export default modalSlice.reducer
