import {ReactElement, useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  AiFillCaretLeft,
  AiFillCaretRight,
  AiOutlineZoomOut,
  AiOutlineZoomIn
} from 'react-icons/ai'
import {IoIosCloudDownload} from 'react-icons/io'
import {MdCloseFullscreen} from 'react-icons/md'
import OutsideClickHandler from 'react-outside-click-handler'
import {download} from '../scripts/download'
import ReactTooltip from 'react-tooltip'
import {
  setVisible,
  nextImg,
  previousImg,
  ImageInfo
} from '../features/modal/modalSlice'
import {usePrevious} from '../hooks/usePrevious'
import {debounceWithDelay} from '../scripts/debounce'
import {max, min} from '../scripts/math'
import {useAppSelector} from '../hooks/hooks'

const ZOOM_MAX: number = 200
const ZOOM_MIN: number = 50
const ZOOM_STEP: number = 50
const ICON_SIZE: number = 22

interface Props {
  zoom: number
  setZoom: (zoom: number) => void
}
const ImageModalWidgets = ({zoom, setZoom}: Props): ReactElement => {
  const dispatch = useDispatch()

  const leftRef = useRef<HTMLDivElement>(null)
  const rightRef = useRef<HTMLDivElement>(null)
  const images: ImageInfo[] = useAppSelector(state => state.modal.images)
  const index: number = useAppSelector(state => state.modal.index) // between 0 and images.length - 1
  const previousIndex = usePrevious(index)

  useEffect(() => {
    process.env.DEBUG === 'true' && console.log('index changed !')
    if (index - (previousIndex || 0) > 0) {
      if (rightRef.current) {
        ReactTooltip.hide(rightRef.current)
        ReactTooltip.show(rightRef.current)
      }
    } else if (leftRef.current) {
      ReactTooltip.hide(leftRef.current)
      ReactTooltip.show(leftRef.current)
    }
  }, [index, previousIndex])

  const [typingZoom, setTypingZoom] = useState<boolean>(false)
  const onEnterZoom = (e: React.KeyboardEvent) => {
    const key = e.key
    const regex = /\d|\./
    if (!regex.test(key)) {
      if (key === 'Enter') {
        let typedZoom = (
          document.getElementById('zoom-level') as HTMLInputElement
        )?.value
        process.env.DEBUG === 'true' && console.log(typedZoom)
        if (!isNaN(Number(typedZoom))) {
          setZoom(max(ZOOM_MIN, min(ZOOM_MAX, Number(typedZoom))))
        }
        setTypingZoom(false)
      }
      e.preventDefault()
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    process.env.DEBUG === 'true' && console.log('onKeyPressedModal')
    process.env.DEBUG === 'true' && console.log(event.key)
    debounceWithDelay(() => {
      switch (event.key) {
        case 'Escape':
          dispatch(setVisible(false))
          ReactTooltip.hide()
          break
        case 'ArrowRight':
          dispatch(nextImg())
          break
        case 'ArrowLeft':
          dispatch(previousImg())
          break
        case 'ArrowUp':
          setZoom(min(ZOOM_MAX, zoom + ZOOM_STEP))
          break
        case 'ArrowDown': {
          setZoom(max(ZOOM_MIN, zoom - ZOOM_STEP))
        }
      }
    }, 50)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  return (
    <div className="modal-widgets">
      <div className="modal-arrows">
        <div
          ref={leftRef}
          onClick={() => dispatch(previousImg())}
          data-tip={index <= 0 ? '' : 'Back ' + index + ' of ' + images.length}
          className={'modal-left ' + (index <= 0 ? 'disabled' : '')}>
          <AiFillCaretLeft size={ICON_SIZE} />
        </div>
        <div
          ref={rightRef}
          onClick={() => dispatch(nextImg())}
          data-tip={
            index >= images.length - 1
              ? ''
              : 'Next ' + (index + 2) + ' of ' + images.length
          }
          className={
            'modal-right ' + (index >= images.length - 1 ? 'disabled' : '')
          }>
          <AiFillCaretRight size={ICON_SIZE} />
        </div>
      </div>
      <div>
        <AiOutlineZoomOut
          onClick={() => setZoom(max(ZOOM_MIN, zoom - ZOOM_STEP))}
          data-tip={zoom <= ZOOM_MIN ? '' : 'Zoom out -'}
          className={'modal-zoom-out ' + (zoom <= ZOOM_MIN ? 'disabled' : '')}
          size={ICON_SIZE}
        />
        <OutsideClickHandler
          onOutsideClick={() => {
            if (typingZoom) {
              setTypingZoom(false)
            }
          }}>
          <div
            className="modal-zoom-level-wrapper"
            onClick={() => {
              setTypingZoom(true)
              setTimeout(
                () => document.getElementById('zoom-level')?.focus(),
                10
              )
            }}>
            {typingZoom ? (
              <>
                <input
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    onEnterZoom(e)
                  }
                  type="text"
                  name="zoom-level"
                  id="zoom-level"
                />
                <span>%</span>
              </>
            ) : (
              <span className="modal-zoom-level">{zoom} %</span>
            )}
          </div>
        </OutsideClickHandler>
        <AiOutlineZoomIn
          onClick={() => setZoom(min(ZOOM_MAX, zoom + ZOOM_STEP))}
          data-tip={zoom >= ZOOM_MAX ? '' : 'Zoom in +'}
          className={'modal-zoom-in ' + (zoom >= ZOOM_MAX ? 'disabled' : '')}
          size={ICON_SIZE}
        />
      </div>
      <a
        href={
          process.env.REACT_APP_BACKEND_URL + '/download/' + images[index].src
        }
        onClick={e => {
          e.preventDefault()
          download(
            process.env.REACT_APP_BACKEND_URL +
              '/download/' +
              images[index].src,
            images[index].src?.split('/').pop()
          )
          return false
        }}
        download={images[index].src?.split('/').pop()}
        data-tip="Download">
        <IoIosCloudDownload className="modal-download" size={ICON_SIZE} />
      </a>
      <MdCloseFullscreen
        onClick={() => {
          dispatch(setVisible(false))
          ReactTooltip.hide()
        }}
        data-tip="Close (esc)"
        className="modal-close"
        size={ICON_SIZE}
      />
    </div>
  )
}
export default ImageModalWidgets
