import React, {
  useEffect,
  useState,
  CSSProperties,
  useRef,
  useCallback
} from 'react'
import useScript from '../hooks/useScript'
import {
  init,
  navigateToProjectByIndex,
  closeByIndex,
  openProjectByIndex
} from '../scripts/project'
import {Project} from '../state/types'
import {ProjectItem} from './ProjectItem'

import '../styles/CircularProgressBar.scss'
import '../styles/Project.scss'
import {initProgressBar} from '../scripts/progressBar'
import HashLoader from 'react-spinners/HashLoader'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {fetchProjects} from '../features/projects/projectsSlice'
import {useAppSelector} from '../hooks/hooks'
import {
  setIndex as setImageIndex,
  setImages,
  setVisible
} from '../features/modal/modalSlice'
import i18n from '../i18n'

const ProjectList = () => {
  const TIME_BEFORE_ADDING_IMAGES_LISTENERS = 50

  const NAVIGATION_DEBOUNCE_TIME = 50
  let navigationTimer = new Date()
  const debounceNavigation = (f: Function) => {
    const now = new Date()
    const timeDiff = now.getTime() - navigationTimer.getTime()
    if (timeDiff > NAVIGATION_DEBOUNCE_TIME) {
      navigationTimer = now
      f()
    }
  }

  const [t] = useTranslation()
  const dispatch = useDispatch()

  // Show a loading screen
  const override: CSSProperties = {
    display: 'block',
    margin: '0 auto'
  }

  const addImagesListeners = useCallback(
    (project: HTMLElement) => {
      Array.from(project.getElementsByTagName('img')).forEach(
        (item: HTMLImageElement, index: number) => {
          item.addEventListener(
            'click',
            () => {
              dispatch(setImageIndex(index))
              dispatch(setVisible(true))
            },
            false
          )
        }
      )
    },
    [dispatch]
  )

  const addImagesListenersByIndex = useCallback(
    (index: number) => {
      const projects = Array.from(
        document.getElementsByClassName('project')
      ) as HTMLElement[]
      for (const project of projects) {
        if (Number(project.getAttribute('data-key')) === index) {
          addImagesListeners(project)
          break
        }
      }
    },
    [addImagesListeners]
  )

  // Load progress bar script
  const progressStatus = useScript('../scripts/progressBar.ts')
  // Load animation script
  const status = useScript('../scripts/project.ts')

  // Get URL projectId parameter
  const urlParams = new URLSearchParams(window.location.search)
  const projectIdTmp = urlParams.get('project')
  const projectId = useRef(
    projectIdTmp && !isNaN(+projectIdTmp) ? Number(projectIdTmp) : null
  )

  // Fetch Strapi data
  const projects: Project[] = useAppSelector(state => state.projects.projects)
  const [loadedProjects, setLoadedProjects] = useState<boolean[]>([])
  const [loading, setLoading] = useState(false)
  const [projectsFetched, setProjectsFetched] = useState(false)
  useEffect(() => {
    setProjectsFetched(false)
    setLoading(true)
    dispatch(fetchProjects(i18n.language)).then((res: any) => {
      setProjectsFetched(true)
    })
  }, [dispatch])

  useEffect(() => {
    if (status && projectsFetched && progressStatus) {
      setLoading(false)
    }
  }, [status, projectsFetched, progressStatus])

  useEffect(() => {
    if (!loading) {
      // Load project in URL parameter
      let arr = []
      let selectedIndex = -1
      for (let i = 0; i < projects.length; ++i) {
        let isSelected = projectId.current === projects[i].id
        arr.push(isSelected)
        if (isSelected) {
          selectedIndex = i
        }
      }
      setLoadedProjects(arr)

      init()
      initProgressBar()

      setTimeout(() => {
        if (selectedIndex !== -1) {
          addImagesListenersByIndex(selectedIndex)
        }
      }, TIME_BEFORE_ADDING_IMAGES_LISTENERS)
    }
  }, [loading, projects, addImagesListenersByIndex])
  // Update when the page loaded OR when the projects are refreshed (to bind the event listeners to the new projects)

  const DEBOUNCE_TIME = 500
  let timer = new Date()

  const debounce = (f: Function) => {
    const now = new Date()
    const timeDiff = now.getTime() - timer.getTime()
    if (timeDiff > DEBOUNCE_TIME) {
      timer = now
      f()
    }
  }

  const handleCloseProject = (
    index: number,
    closeAll: boolean,
    scrollBack: boolean
  ) => debounce(() => closeByIndex(index, closeAll, scrollBack))

  const handleOpenProject = (index: number) => {
    debounce(() => {
      const currentProject = getCurrentProject(index)

      if (currentProject) {
        if (!loadedProjects[index]) {
          loadedProjects[index] = true
          setLoadedProjects([...loadedProjects])
          setTimeout(() => {
            addImagesListeners(currentProject)
          }, TIME_BEFORE_ADDING_IMAGES_LISTENERS)
        }
        openProjectByIndex(index)
        dispatch(
          setImages(
            Array.from(currentProject.getElementsByTagName('img')).map(
              (elem: HTMLImageElement) => {
                return {src: elem.src, alt: elem.alt}
              }
            )
          )
        )
      }
    })
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    const currentProject = getCurrentProject()
    const index = currentProject
      ? Number(currentProject.getAttribute('data-key'))
      : null
    if (index !== null && !isNaN(index)) {
      if (projectId.current !== null) {
        switch (e.key) {
          case 'Escape':
            handleCloseProject(index, true, true)
            break
          case 'ArrowLeft':
            handleOpenProject(index + 1)
            break
          case 'ArrowRight':
            handleOpenProject(index - 1)
            break
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  return (
    <React.Fragment>
      <HashLoader
        color={
          localStorage.getItem('lightMode') === 'default'
            ? '#6b7abb'
            : '#96dcee'
        }
        loading={loading}
        cssOverride={override}
        size={80}
        aria-label="Loading Spinner"
      />

      {loading ? (
        <></>
      ) : (
        <React.Fragment>
          <div className="circular">
            <div className="inner"></div>
            <div className="number">100%</div>
            <div className="circle">
              <div className="bar left">
                <div className="progress"></div>
              </div>
              <div className="bar right">
                <div className="progress"></div>
              </div>
            </div>
          </div>
          <div className={'project-dot-list'}>
            <div className={'project-arrow-left'}></div>
            {projects?.length
              ? projects.map((project: Project, index: number) => (
                  <div
                    key={project.id}
                    data-key={index}
                    className={'project-dot-container'}
                    onClick={() =>
                      debounceNavigation(() => navigateToProjectByIndex(index))
                    }>
                    <div className={'project-dot'}></div>
                  </div>
                ))
              : null}
            <div className={'project-arrow-right'}></div>
          </div>
          <div className={'project-list scrollable-x'}>
            <div className={'inner project-style'}>
              {projects?.length ? (
                projects.map((project: Project, index: number) => (
                  <ProjectItem
                    onClick={() => {
                      handleOpenProject(index)
                    }}
                    key={project.id}
                    project={project}
                    index={index}
                    slideLeft={() => {
                      handleOpenProject(index - 1)
                    }}
                    closeProject={() => {
                      handleCloseProject(index, true, true)
                    }}
                    slideRight={() => {
                      handleOpenProject(index + 1)
                    }}
                    nbProjects={projects.length}
                    loadContent={loadedProjects[index]}
                  />
                ))
              ) : (
                <div className="error-wrapper">
                  <p className="error">{t('no-projects')}</p>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
export default ProjectList

function getCurrentProject(index?: number) {
  const projects = Array.from(
    document.getElementsByClassName('project')
  ) as HTMLElement[]
  if (index === undefined) {
    for (const project of projects) {
      if (project.classList.contains('active')) {
        return project
      }
    }
    return null
  }
  for (const project of projects) {
    if (Number(project.getAttribute('data-key')) === index) {
      return project
    }
  }
  return null
}
