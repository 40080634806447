import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import Layout from './components/Layout'
import { Provider } from 'react-redux'
import store from './store/store'

// import i18n (needs to be bundled ;))
import './i18n'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <Suspense fallback="loading">
          <Layout />
        </Suspense>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
