import React, { FC, ReactElement } from 'react';
import '../styles/ThemeToggle.scss'

interface Props {
  lightMode: string,
  toggleTheme:  React.ChangeEventHandler
}
export const ThemeToggle: FC<Props> = ({lightMode, toggleTheme}): ReactElement => {
  return (
    <React.Fragment>
      <input aria-label="toggle dark light mode" type="checkbox" id="toggle" className="toggle--checkbox" checked={lightMode==='inverted'} onChange={toggleTheme}></input>
      <label htmlFor="toggle" className="toggle--label">
        <span className="toggle--label-background"></span>
      </label>
      <div className="background"></div>
    </React.Fragment>
  )
}
export default ThemeToggle