import React, {ReactElement, useEffect, useRef, useState} from 'react'

import {ImageInfo, setVisible} from '../features/modal/modalSlice'
import {useAppSelector, useAppDispatch} from '../hooks/hooks'
import '../styles/Modal.scss'
import ReactTooltip from 'react-tooltip'
import ImageModalWidgets from './ImageModalWidgets'

const ZOOM_DEFAULT: number = 100
const ZOOM_STEP: number = 50

const ImageModal = (): ReactElement => {
  const dispatch = useAppDispatch()
  const images: ImageInfo[] = useAppSelector(state => state.modal.images)
  const index: number = useAppSelector(state => state.modal.index) // between 0 and images.length - 1
  const visible: boolean = useAppSelector(state => state.modal.visible)
  const [zoom, setZoom] = useState<number>(ZOOM_DEFAULT)
  const disableExit = useRef(false)

  useEffect(() => {
    if (visible) {
      modalRef.current?.focus()
      disableExit.current = true
      setTimeout(() => (disableExit.current = false), 10)
      process.env.DEBUG === 'true' && console.log('Loaded tool-tips')
      ReactTooltip.rebuild()
    }
  }, [visible])

  const modalRef = useRef<HTMLDivElement>(null)

  const bgClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    if (
      !disableExit.current &&
      ((event.target as HTMLElement).classList.contains(
        'modal-content-wrapper'
      ) ||
        (event.target as HTMLElement).classList.contains('modal-wrapper'))
    ) {
      dispatch(setVisible(false))
      ReactTooltip.hide()
    }
  }

  const handleImgClick = () => {
    if (zoom !== ZOOM_DEFAULT) {
      setZoom(ZOOM_DEFAULT)
    } else {
      setZoom(zoom + ZOOM_STEP)
    }
  }

  return (
    <>
      {visible ? (
        <div
          ref={modalRef}
          tabIndex={-1}
          id="modal"
          className="modal-wrapper"
          onClick={bgClickHandler}>
          <div className="modal-content-wrapper" onClick={bgClickHandler}>
            <img
              onClick={() => handleImgClick()}
              className="modal-content image"
              id="image-modal"
              src={images[index].src}
              alt={images[index].alt}
              style={{transform: 'scale(' + zoom + '%)'}}
            />
          </div>
          <ImageModalWidgets zoom={zoom} setZoom={setZoom} />
        </div>
      ) : null}
    </>
  )
}

export default ImageModal
