import React, {FC, ReactElement} from 'react'
import {Project} from '../state/types'
import ReactMarkdown from 'react-markdown'
import {useTranslation} from 'react-i18next'
import {
  AiFillCloseCircle,
  AiFillCaretLeft,
  AiFillCaretRight
} from 'react-icons/ai'
import {getHighestResImage} from '../scripts/strapi'
import {languages} from '../i18n'

interface Props {
  project: Project
  index: number
  onClick: Function
  slideLeft: Function
  closeProject: Function
  slideRight: Function
  nbProjects: number
  loadContent: boolean
}

export const ProjectItem: FC<Props> = ({
  project,
  index,
  onClick,
  slideLeft,
  closeProject,
  slideRight,
  nbProjects,
  loadContent
}): ReactElement => {
  function formatMyDate(value: string | Date, locale = languages[0]) {
    return new Date(value).toLocaleDateString(locale)
  }

  const [t] = useTranslation()
  return (
    <div
      className={'project'}
      data-project-id={project.id}
      data-key={index}
      onClick={() => onClick()}>
      <div
        onClick={() => onClick()}
        className={'project-background'}
        style={{
          backgroundImage: getHighestResImage(
            project.attributes.cover?.data?.attributes?.formats
          )
        }}></div>
      <div className={'project-widgets'}>
        <div
          data-key={index - 1}
          className="padding-s project-slide-left-event"
          onClick={() => slideLeft()}>
          <AiFillCaretLeft
            data-key={index - 1}
            className={
              'project-slide-left-event clickable-icon ' +
              (Number(index) - 1 < 0 ? 'disabled' : '')
            }
            size={32}
          />
        </div>
        <div
          className="padding-s project-close-item"
          onClick={() => closeProject()}>
          <AiFillCloseCircle
            className={'project-close-item clickable-icon'}
            size={32}
          />
        </div>
        <div
          data-key={index + 1}
          className="padding-s project-slide-right-event"
          onClick={() => slideRight()}>
          <AiFillCaretRight
            data-key={index + 1}
            className={
              'project-slide-right-event clickable-icon ' +
              (Number(index) + 1 >= nbProjects ? 'disabled' : '')
            }
            size={32}
          />
        </div>
      </div>
      <div className={'project-container'}>
        <div className={'project-header'}>
          <div className="title-wrapper">
            <a
              className={'title font-l'}
              href={project.attributes.url ? project.attributes.url : '#'}>
              {project.attributes.title
                ? project.attributes.title.toUpperCase()
                : 'TITLE MISSING'}
            </a>
          </div>
          <ul>
            <li>
              <span className="bold">{t('started')}</span> -{' '}
              {project.attributes.startDate
                ? formatMyDate(project.attributes.startDate)
                : 'DATE MISSING'}
            </li>
            {project.attributes.endDate ? (
              <li>
                <span className="bold">{t('completed')}</span> -{' '}
                {project.attributes.endDate
                  ? formatMyDate(project.attributes.endDate)
                  : 'DATE MISSING'}
              </li>
            ) : null}
            {project.attributes.role ? (
              <li>
                <span className="bold">{t('role')}</span> -{' '}
                {project.attributes.role.toUpperCase()}
              </li>
            ) : null}
            {project.attributes.client ? (
              <li>
                <span className="bold">{t('client')}</span> -{' '}
                {project.attributes.client.toUpperCase()}
              </li>
            ) : null}
            {project.attributes.description ? (
              <li>
                <span className="bold">{project.attributes.description}</span>
              </li>
            ) : null}
          </ul>
        </div>

        <div className={'project-body'}>
          {project.attributes.video ? (
            <div className="videowrapper">
              <iframe
                sandbox="allow-presentation allow-forms allow-pointer-lock allow-same-origin allow-scripts allow-top-navigation"
                width="560"
                height="315"
                src={project.attributes.video}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ) : null}
          {loadContent ? (
            <ReactMarkdown>
              {project.attributes.content
                ? project.attributes.content
                : 'CONTENT MISSING'}
            </ReactMarkdown>
          ) : null}
        </div>
      </div>
    </div>
  )
}
