const orderedPreferredFormats = ['large', 'medium', 'small', 'thumbnail']
type Formats = {
  [Key in (typeof orderedPreferredFormats)[number]]: {
    url: string
  } | null
}

export const getHighestResImageUrl = (
  availableFormats: Formats | undefined
) => {
  if (!availableFormats) {
    return 'image/error404.png'
  }
  for (const format of orderedPreferredFormats) {
    if (availableFormats[format]?.url) {
      return availableFormats[format]?.url
    }
  }
  return 'image/error404.png'
}
export const getHighestResImage = (availableFormats: Formats | undefined) => {
  return `url(${getHighestResImageUrl(availableFormats)})`
}
