import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import '../styles/Splash.scss'
import '../styles/Slider.scss'
import {Link} from 'react-router-dom'
import firms from '../assets/data/firms.json'
import {Firm} from '../state/types'
import {ProjectIcon} from './ProjectIcon'
import TextTransition from 'react-text-transition'
import {HiOutlineCursorArrowRays} from 'react-icons/hi2'

const Splash = () => {
  const [t] = useTranslation()
  const [selectedFirm, setSelectedFirm] = useState<Firm | null>()
  return (
    <React.Fragment>
      <div className="splash-parent">
        <div className="splash-div1 card">
          <div className="center-wrapper">
            <h2>{t('splash.welcome')}</h2>
            <br />
            <h1>
              <span className="font-m">{t('splash.introduce-name')}</span>{' '}
              ANTONIN MARXER
            </h1>
            <br />
            <h2>{t('splash.description').toUpperCase()}</h2>
          </div>
        </div>
        <Link className="splash-div2 card" to="/about">
          <h2>{t('splash.me.title').toUpperCase()}</h2>
          <HiOutlineCursorArrowRays
            className="highlighted float-right"
            size={24}
          />
          <div className="splash-wrapper">
            <ul>
              <li>{t('splash.me.perseverant')}</li>
              <li>{t('splash.me.dynamic')}</li>
              <li>{t('splash.me.methodic')}</li>
            </ul>
            <p className="primary-color">{t('splash.me.read-more')}</p>
          </div>
        </Link>
        <Link className="splash-div3 card" to="/projects">
          <div>
            <h2>{t('splash.projects').toUpperCase()}</h2>
            <HiOutlineCursorArrowRays
              className="highlighted float-right"
              size={24}
            />
          </div>
          <div className="splash-wrapper color-svg">
            <ProjectIcon />
          </div>
        </Link>
        <div className="splash-div4 card">
          <div className="splash-header">
            <div className="flex-space">
              <h2>{t('splash.firms').toUpperCase()}</h2>
              <p>{t('splash.firms-subtitle')}</p>
              {selectedFirm ? (
                <a
                  className="selected-firm"
                  href={selectedFirm.url ? selectedFirm.url : '#'}>
                  <TextTransition inline={true}>
                    {selectedFirm.name}
                  </TextTransition>
                </a>
              ) : null}
            </div>
          </div>
          <div className="flex-down">
            <div className="slider">
              <div className="slide-track">
                {firms
                  ? [...Array(3)].map(() =>
                      firms.map((f: Firm) => (
                        <div
                          key={f.name}
                          className="slide clickable"
                          onClick={() => setSelectedFirm(f)}>
                          <img src={'image/firms/' + f.image} alt={f.name} />
                        </div>
                      ))
                    )
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Splash
