export const max = (a: number, b: number) => {
  if (a > b) {
    return a
  }
  return b
}

export const min = (a: number, b: number) => {
  if (a < b) {
    return a
  }
  return b
}
