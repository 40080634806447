import React, {FC, ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import '../styles/Footer.scss'

interface Props {
  /* destructured props and types */
}
export const About: FC<Props> = (): ReactElement => {
  const [t] = useTranslation()

  return (
    <div className="footer">
      <div className="left">
        <Link data-item={t('legal')} className="link" to="/legal">
          {t('legal')}
        </Link>
      </div>
      <div className="right">
        <a href="mailto:antoninmarxer@orange.fr">antoninmarxer@orange.fr</a>
        <a href="tel:+33644869778">+336.44869778</a>
      </div>
    </div>
  )
}

export default About
