import React, {FC, ReactElement, useMemo, useState} from 'react'
import '../styles/About.scss'

import {BiUpvote, BiDownvote} from 'react-icons/bi'
import {IoIosCloudDownload, IoMdMail} from 'react-icons/io'
import {BsFillPeopleFill} from 'react-icons/bs'
import {AiFillLinkedin, AiFillEye, AiFillGithub} from 'react-icons/ai'
import {IconContext} from 'react-icons'
import {CategoryTransition, Direction, Category} from '../state/types'
import categoriesJson from '../assets/data/categories.json'
import skills from '../assets/data/skills.json'
import SkillList from './SkillList'
import {useAppSelector} from '../hooks/hooks'
import {useDispatch} from 'react-redux'
import {postVote} from '../features/counter/counterSlice'
import {Trans, useTranslation} from 'react-i18next'

interface Props {
  /* destructured props and types */
}
// eslint-disable-next-line no-empty-pattern
export const About: FC<Props> = ({}): ReactElement => {
  const [t] = useTranslation()
  const rating = useAppSelector(state => state.counter.rating)
  const vote = useAppSelector(state => state.counter.vote)
  const uniqueVisitors = useAppSelector(state => state.visitors.uniqueVisitors)
  const views = useAppSelector(state => state.visitors.views)
  const visitorId = useAppSelector(state => state.visitors.visitorId)

  // Vote handler
  const dispatch = useDispatch()
  const upVoteHandler = async () => {
    dispatch(
      postVote({
        visitorId: visitorId,
        vote: vote,
        up: true
      })
    )
  }
  const downVoteHandler = () => {
    dispatch(
      postVote({
        visitorId: visitorId,
        vote: vote,
        up: false
      })
    )
  }
  // Define all distinct skill categories
  const categories = [...categoriesJson].sort((a: Category, b: Category) =>
    a.index > b.index ? 1 : -1
  )

  // Slider for skills category
  // Handle right and left arrows
  const [category, changeCategory] = useState<CategoryTransition>({
    index: 0,
    direction: Direction.NEXT
  })
  const leftSliderHandler = () => {
    if (category.index > 0) {
      changeCategory({index: category.index - 1, direction: Direction.PREVIOUS})
    } else {
      changeCategory({
        index: categories.length - 1,
        direction: Direction.PREVIOUS
      })
    }
  }
  const rightSliderHandler = () => {
    if (category.index < categories.length - 1) {
      changeCategory({index: category.index + 1, direction: Direction.NEXT})
    } else {
      changeCategory({index: 0, direction: Direction.NEXT})
    }
  }

  const iconStyle = useMemo(() => ({className: 'icon', size: '32px'}), [])

  return (
    <React.Fragment>
      <IconContext.Provider value={iconStyle}>
        <div className="parent">
          <div className="profile">
            <div className="profile-image">
              <img src="image/CVForum-200.jpg" alt="Antonin Marxer" />
              <div className="scrolling-text">
                {[...Array(4)].map(x => (
                  <div key={x}>
                    <div>
                      <span className="scroll-text-1 font-s">
                        {t('scrolltext.1')}
                      </span>
                      <span className="scroll-text-2 font-s">
                        {t('scrolltext.2')}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="profile-content">
              <h1>{t('profile.title')}</h1>
              <div className="content">
                <p>
                  <Trans i18nKey="profile.content" />
                </p>
              </div>
            </div>
          </div>
          <div className="div2 card">
            <div className={`arrow left-arrow`} onClick={leftSliderHandler}>
              <div className={`left-slider`} />
            </div>
            <div className={`arrow right-arrow`} onClick={rightSliderHandler}>
              <div className={`right-slider`} />
            </div>
            <h1>{t('skills.title')}</h1>
            <div className="skill-list-wrapper">
              {
                // For each category, show the corresponding skills
                categories
                  ? categories.map((c: Category, index: number) => {
                      // Render only current category or the previous/next one according
                      // to sliding direction
                      if (
                        index === category.index ||
                        (category.direction === Direction.NEXT &&
                          (index === category.index - 1 ||
                            (category.index === 0 &&
                              index === categories.length - 1))) ||
                        (category.direction === Direction.PREVIOUS &&
                          (index === category.index + 1 ||
                            (category.index === categories.length - 1 &&
                              index === 0)))
                      ) {
                        // filter out the skills of the current category
                        let skillsPerCategory = skills.filter(
                          s => s.category === c.id
                        )
                        // now render only those filtered out skills
                        return (
                          <SkillList
                            skills={skillsPerCategory}
                            key={c.id}
                            index={index}
                            categoryTransition={category}
                            category={c}
                          />
                        )
                      }
                      return null
                    })
                  : null
              }
            </div>
          </div>
          <div className="div3 card">
            <h1>{t('motivations.title')}</h1>
            <p>
              <Trans i18nKey="motivations.content" />
            </p>
          </div>
          <div className="div4 card font-s">
            <a href="mailto:antoninmarxer@orange.fr">
              <IoMdMail className="clickable-icon" />
              antoninmarxer@orange.fr
            </a>
            {/*
            <a href="https://twitter.com/Antonin87993834">
              <BsTwitter className="clickable-icon" />
            </a>
            */}
            <a href="https://linkedin.com/in/antonin-marxer-98447420b">
              <AiFillLinkedin className="clickable-icon" />
              Antonin Marxer
            </a>
            <a href="https://github.com/PheonBest">
              <AiFillGithub className="clickable-icon" />
              @PheonBest
            </a>
            <a id="cv" href={t('cv-path')} download>
              <IoIosCloudDownload className="clickable-icon" />
              {t('cv')}
            </a>
          </div>
          <div className="div5 card">
            <img src="/image/INSA_1A.jpg" alt="Groupe 'Ôlé', 1ère année INSA" />
            <img
              src="/image/TeamTech_3A.jpg"
              alt="Équipe Tech de l'association Forum Organisation, 3ème année INSA"
            />
            <img
              src="/image/FRA_3A.jpg"
              alt="Évènement Forum Rhône-Alpês, 3ème année INSA"
            />
            <img
              src="/image/TeamFRA_3A.jpg"
              alt="Équipe du Forum Organisation, 3ème année INSA"
            />
          </div>
          <div className="div6 card">
            <img
              className="left-image"
              src="/image/statistics_st_thomas_university.jpg"
              alt="Statistics"
            />
            <div className="padding-40px font-m">
              <p>{t('vote')}</p>
              <div className="counter">
                <BiUpvote
                  className={
                    'clickable-icon ' + (vote === 1 ? 'active-icon-up' : '')
                  }
                  onClick={upVoteHandler}
                />
                <div className="counter-fixed-size">
                  <p
                    className={
                      'bold-text counter-text ' + getVoteCounterStyle(vote)
                    }>
                    {rating}
                  </p>
                </div>
                <BiDownvote
                  className={
                    'clickable-icon ' + (vote === -1 ? 'active-icon-down' : '')
                  }
                  onClick={downVoteHandler}
                />
              </div>
              <div className="counter">
                <p>{t('views')}</p>
                <AiFillEye />
                <p className="bold-text">{views}</p>
              </div>
              <div className="counter">
                <p>{t('unique-visitors')}</p>
                <BsFillPeopleFill />
                <p className="bold-text">{uniqueVisitors}</p>
              </div>
            </div>
          </div>
        </div>
      </IconContext.Provider>
    </React.Fragment>
  )
}

function getVoteCounterStyle(vote: number) {
  if (vote === 1) {
    return 'active-text-up'
  }
  if (vote === 0) {
    return ''
  }
  return 'active-text-down'
}

export default About
