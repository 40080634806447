import axios from 'axios'

const projectsAPI = {
  fetchProjects
}
export default projectsAPI

async function fetchProjects(language: string) {
  process.env.DEBUG === 'true' && console.log('fetchProjects')
  process.env.DEBUG === 'true' &&
    console.log(
      process.env.REACT_APP_STRAPI_API_URL + '/projects?locale=' + language
    )

  const res = await axios({
    method: 'GET',
    url:
      (process.env.REACT_APP_STRAPI_API_URL ?? 'http://localhost:1337') +
      '/projects',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept',
      'content-type': 'application/json; charset=utf-8'
    },
    params: {
      locale: language,
      'sort[0]': 'startDate:desc',
      'sort[1]': 'title:asc',
      populate: '*'
    }
  })
  return res
}
